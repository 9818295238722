import React from 'react'
import styled from '@emotion/styled'
import Form, { addNetlify } from '../components/Form'

function ContactUs() {

  return (
    <>
      <FormContainer>
        <NetlifyForm name="contact">
          <Form.Group>
            <Input full name="name" placeholder="Name" />
            <Input full name="email" placeholder="Email" />
          </Form.Group>
          <TextArea name="message" placeholder="Message" full rows={6}></TextArea>
          <FormButton type="submit">Send Message</FormButton>
        </NetlifyForm>
      </FormContainer>
    </>
  )
}

const FormContainer = styled.div`
  margin-top: 10rem;
  list-style-type: none;
  font-size: 4.6rem;
  text-transform: uppercase;
  padding: 6rem;
`

const Input = styled(Form.Input)`
  border: 2px solid black;
`

const TextArea = styled(Form.TextArea)`
  border: 2px solid black;
`

const FormButton = styled.button`
  border: 0;
  border-radius: 10px;
  padding: 1.5rem 2.5rem;
  background-color: none;
  font-size: 2rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  cursor: pointer;
`

const NetlifyForm = addNetlify(Form)


export default ContactUs